import {
    SET_TASKS,
    ADD_TASK,
    REMOVE_TASK,
    SET_ACTIVE_TASK,
    SET_TASK_TRUE_ID,
    SET_TASK_ATTRIBUTE_VALUE,
    SET_TASK_TARGETED,
    UPDATE_TASK,
    REMOVE_LABEL_FROM_TASK,
    SET_TASK_PREVENT_ANIMATION
} from "../constants/tasks";

export const setTasks = (tasks) => ({type: SET_TASKS, tasks});
export const addTask = ({task, taskId}) => ({type: ADD_TASK, task, taskId});
export const removeTask = (taskId) => ({type: REMOVE_TASK, taskId});
export const updateTask = (taskId, task) => ({type: UPDATE_TASK, taskId, task});
export const setActiveTask = (taskId) => ({type: SET_ACTIVE_TASK, taskId});
export const setTaskTrueId = (clientTaskId, trueTaskId) => ({type: SET_TASK_TRUE_ID, clientTaskId, trueTaskId});

export const setTaskAttributeValue = ({taskId, attributeType, value}) => ({type: SET_TASK_ATTRIBUTE_VALUE, taskId, attributeType, value});
export const setTaskTargeted = ({taskId, targeted}) => ({type: SET_TASK_TARGETED, taskId, targeted});
export const removeLabelFromTask = ({taskId, label}) => ({type: REMOVE_LABEL_FROM_TASK, taskId, label});
export const setTaskPreventAnimation = ({taskId, value}) => ({type: SET_TASK_PREVENT_ANIMATION, taskId, value});