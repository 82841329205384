import {useCallback, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { App } from '@capacitor/app';

const AppUrlListener = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const handleAppUrlOpen = useCallback((event) => {
        const slug = event.url.split('.app').pop();
        if (slug && slug !== location.pathname) {
            navigate(slug);
        }
    }, [navigate, location.pathname]);

    useEffect(() => {
        const listener = App.addListener('appUrlOpen', handleAppUrlOpen);

        return () => {
            listener.remove();
        };
    }, [handleAppUrlOpen]);

    return null;
}

export default AppUrlListener;