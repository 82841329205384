// move an item in a list from it's original position (startIndex) to a destination position (endIndex)

import dayjs from "dayjs";

export const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

//Moves an elementFromOneListToAnother
export const moveElementToOtherList = (sourceList, sourceListId, destinationList, destinationListId, sourceElement, sourceElementIndex, destinationElement, destinationElementIndex) => {
    const sourceListClone = Array.from(sourceList);
    const destListClone = Array.from(destinationList);
    const [removed] = sourceListClone.splice(sourceElementIndex, 1);

    destListClone.splice(destinationElementIndex, 0, removed);

    const result = {};
    result[sourceListId] = sourceListClone;
    result[destinationListId] = destListClone;

    return result;
};


let loggedTasks = [];
export const smartSortTasks = (attributeDefinitions, mindset) => {
    // Used for getting a full log of all tasks by the logging block at the bottom of this functions
    // eslint-disable-next-line no-unused-vars
    loggedTasks = []
    return (a, b) => {
        if (!a.attributes || !b.attributes) {
            return 0;
        }

        const {difficulty: aDifficulty, importance: aImportance, fun: aFun} = a.attributes;
        const {difficulty: bDifficulty, importance: bImportance, fun: bFun} = b.attributes;

        if (Object.keys(attributeDefinitions).length > 0) {
            const {difficulty, urgency, importance, fun} = attributeDefinitions;

            //We need to disregard mindset modifiers & difficulty/fun if mindset has not been provided (e.g sorting base task list)
            let workingMindset = mindset ? mindset : {
                energy: 0,
                mood: 0
            }
            let workingDifficulty = mindset ? difficulty : {weight: 0}
            let workingFun = mindset ? fun : {weight: 0}

            // A function getFunWeight that takes in a fun value and a mood value and returns a weighted fun value
            // When mood is low, a higher fun parameter returns a higher weighted fun value
            // When mood is high, a higher fun parameter returns a lower weighted fun value
            const getFunWeight = (fun, mood) => 9 - Math.abs(Math.abs(9 - (mood - 1)) - (fun - 1))

            // A function getDifficultyWeight that takes in a energy value and a difficulty value and returns a weighted difficulty value
            // When energy is low, a higher difficulty parameter returns a lower weighted fun value
            // When energy is high, a higher difficulty parameter returns a higher weighted fun value
            const getDifficultyWeight = (difficulty, energy) => Math.abs(Math.abs(9 - (difficulty - 1)) - (energy - 1))

            let aUrgency = 0, bUrgency = 0;
            if (a.deadline) {
                const createdInSeconds = Math.round(a.created / 1000);
                const timeTillDeadline = a.deadline - dayjs().unix();
                const timeToComplete = a.deadline - createdInSeconds;
                const timeToCompletePercent = Math.round((timeTillDeadline / timeToComplete) * 100);
                aUrgency = Math.ceil((100 - timeToCompletePercent) / 20);
            }
            if (b.deadline) {
                const createdInSeconds = Math.round(b.created / 1000);
                const timeTillDeadline = b.deadline - dayjs().unix();
                const timeToComplete = b.deadline - createdInSeconds;
                const timeToCompletePercent = Math.round((timeTillDeadline / timeToComplete) * 100);
                bUrgency = Math.ceil((100 - timeToCompletePercent) / 20);
            }

            const aWeight =
                getDifficultyWeight(aDifficulty, workingMindset.energy) * workingDifficulty.weight
                + getFunWeight(aFun, workingMindset.mood) * workingFun.weight
                + (mindset ? (aUrgency * 0.1) : (aUrgency * urgency.weight)) //dampen urgency if mindset is provided
                + (mindset ? (aImportance * 0.15) : (aImportance * importance.weight)) //dampen importance if mindset is provided
                + (a.targeted ? 1000 : 0);
            const bWeight =
                getDifficultyWeight(bDifficulty, workingMindset.energy) * workingDifficulty.weight
                + getFunWeight(bFun, workingMindset.mood) * workingFun.weight
                + (mindset ? (bUrgency * 0.1) : (bUrgency * urgency.weight)) //dampen urgency if mindset is provided
                + (mindset ? (bImportance * 0.15) : (bImportance * importance.weight)) //dampen importance if mindset is provided
                + (b.targeted ? 1000 : 0);

            // Task Attribute & Mood Logging
            /*
            if (mindset) {
                if (!loggedTasks.includes(a.taskId)) {
                    loggedTasks.push(a.taskId);

                    console.log(`${a.name} ${aWeight}
              difficulty: ${getDifficultyWeight(aDifficulty, workingMindset.energy)} (difficulty: ${aDifficulty} energy: ${workingMindset.energy})
              urgency: ${(mindset ? (aUrgency * 0.1) : (aUrgency * urgency.weight))} (${aUrgency})
              importance: ${(mindset ? (aImportance * 0.2) : (aImportance * importance.weight))} (${aImportance})
              fun: ${getFunWeight(aFun, workingMindset.mood)} (fun: ${aFun} mood: ${workingMindset.mood})`)
                }

                if (!loggedTasks.includes(b.taskId)) {
                    loggedTasks.push(b.taskId);

                    console.log(`${b.name} ${bWeight}
              difficulty: ${getDifficultyWeight(bDifficulty, workingMindset.energy)} (difficulty: ${bDifficulty} energy: ${workingMindset.energy})
              urgency: ${(mindset ? (bUrgency * 0.1) : (bUrgency * urgency.weight))} (${bUrgency})
              importance: ${(mindset ? (bImportance * 0.2) : (bImportance * importance.weight))} (${bImportance})
              fun: ${getFunWeight(bFun, workingMindset.mood)} (fun: ${bFun} mood: ${workingMindset.mood})`)

                }
            }*/

            if (aWeight > bWeight) {
                return -1;
            }
            if (aWeight < bWeight) {
                return 1;
            }

            return 0;
        }

        return 0;
    }
};
