import {
    SET_TASKS,
    ADD_TASK,
    REMOVE_TASK,
    SET_ACTIVE_TASK,
    UPDATE_TASK,
    SET_TASK_TRUE_ID,

    SET_TASK_ATTRIBUTE_VALUE,
    SET_TASK_TARGETED,
    REMOVE_LABEL_FROM_TASK,
    SET_TASK_PREVENT_ANIMATION
} from "../constants/tasks";

const initialValue = {
    activeTask: null,
    taskSet: {}
};

export default (state = initialValue, action) => {
    switch (action.type) {
        case SET_TASKS:
            return {
                ...state,
                taskSet: action.tasks
            }
        case ADD_TASK:
            const newTasks = {...state.taskSet};
            const { taskId, task } = action;
            newTasks[taskId] = {
                ...task,
                taskId: taskId,
                listId: "backlog", // This is also set on the server on createTask
                preventAnimation: false
            };

            return {
                ...state,
                taskSet: newTasks
            };
        case REMOVE_TASK: {
            const {taskId} = action;
            const newTasks = {...state.taskSet};

            delete newTasks[taskId];

            return {
                ...state,
                taskSet: newTasks
            };
        }
        case UPDATE_TASK: {
            const { taskId, task } = action;
            const newTasks = {...state.taskSet};

            newTasks[taskId] = task;
            return {
                ...state,
                taskSet: newTasks
            }
        }
        case SET_ACTIVE_TASK: {
            return {
                ...state,
                activeTask: action.taskId
            }
        }
        case SET_TASK_TRUE_ID: {
            const newTaskSet = {...state.taskSet};
            const { clientTaskId, trueTaskId } = action;

            if (clientTaskId !== trueTaskId) {
                const task = newTaskSet[clientTaskId];
                if(!task) {
                    // Nasty but this stops a bug where the task does not appear to be in the task set when it tries to set true task id
                    // Adding this break-out means it still functions as intended for some reaason
                    return {...state};
                }

                newTaskSet[trueTaskId] = {
                    ...task,
                    taskId: trueTaskId
                };
                delete newTaskSet[clientTaskId];

                return {
                    ...state,
                    taskSet: newTaskSet
                };
            } else {
                return {...state};
            }
        }
        case SET_TASK_ATTRIBUTE_VALUE: {
            const newTaskSet = {...state.taskSet};
            const { taskId, attributeType, value } = action;
            const task = newTaskSet[taskId];
            task.attributes[attributeType] = value;

            return {
                ...state,
                taskSet: newTaskSet
            };
        }
        case SET_TASK_TARGETED: {
            const newTaskSet = {...state.taskSet};
            const { taskId, targeted } = action;
            const task = newTaskSet[taskId];

            task.targeted = targeted;
            return {
                ...state,
                taskSet: newTaskSet
            };
        }
        case REMOVE_LABEL_FROM_TASK: {
            const newTaskSet = {...state.taskSet};
            const { taskId, label } = action;
            const task = newTaskSet[taskId];

            const newLabelArray = [...task.labels];
            const labelIndex = newLabelArray.indexOf(label);

            if(labelIndex > -1 ) {
                newLabelArray.splice(labelIndex, 1);
            }

            task.labels = newLabelArray;

            return {
                ...state,
                taskSet: newTaskSet
            };
        }
        case SET_TASK_PREVENT_ANIMATION: {
            //TODO Commented out on confirmTaskCreation socket listener, delete if not needed
            const newTaskSet = {...state.taskSet};
            const { taskId, value } = action;
            const task = newTaskSet[taskId];
            task.preventAnimation = value;

            return {
                ...state,
                taskSet: newTaskSet
            };
        }
        default:
            return state
    }
}