import {dispatch} from "../../../redux/store";
import {setTaskPreventAnimation, setTaskTrueId} from "../../../redux/actions/tasks";


export default ({clientTaskId, taskId}) => {
    setTimeout(() => {
        //TODO Delete if no problems
        //dispatch(setTaskPreventAnimation({taskId: clientTaskId, value: true})); //Otherwise, causes janky fading in/out
        dispatch(setTaskTrueId(clientTaskId, taskId));
    }, 500)
}