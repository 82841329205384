import firebase from "firebase";
import * as firebaseui from 'firebaseui'
import { dispatch } from "../redux/store";
import { setUser } from "../redux/actions/user"
import { Capacitor } from "@capacitor/core";

export default {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            let user = authResult.user;
            let credential = authResult.credential;
            let isNewUser = authResult.additionalUserInfo.isNewUser;
            let providerId = authResult.additionalUserInfo.providerId;
            let operationType = authResult.operationType;
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.

            const {uid, email, displayName} = user;
            dispatch(setUser({
                uid,
                email,
                displayName
            }));
        },
        signInFailure: function(error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            return error;
        },
        uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
        }
    },
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    // Query parameter name for mode.
    queryParameterForWidgetMode: 'mode',
    // Query parameter name for sign in success url.
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: process.env.NODE_ENV === 'production' ? 'https://mindstack.app' : Capacitor.isNativePlatform() ? "https://mindstack.app/native-app" : '/',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // Whether the display name should be displayed in the Sign Up page.
            requireDisplayName: true
        },
    ],
    // Set to true if you only have a single federated provider like
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID and you would like to
    // immediately redirect to the provider's site instead of showing a
    // 'Sign in with Provider' button first. In order for this to take
    // effect, the signInFlow option must also be set to 'redirect'.
    immediateFederatedRedirect: false,
};