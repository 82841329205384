import { PageConstants } from "../../constants";
import {dispatch} from "../../redux/store";
import {setTasks} from "../../redux/actions/tasks";
import {setTaskLabels} from "../../redux/actions/taskLabels";
import { setLabelFilters, setMindset} from "../../redux/actions/user";
import {setRecurringTasks} from "../../redux/actions/recurringTasks";
import {setUsers} from "../../redux/actions/admin";

const pageData =  ({page, data}) => {
   switch(page) {
      case PageConstants.TASK_PAGE: {
         if(data) {
            const { tasks, labels, mindset, filters } = data;
            dispatch(setTasks(tasks));
            dispatch(setTaskLabels(labels));
            dispatch(setMindset(mindset));
            dispatch(setLabelFilters(filters.labels));
         }
         break;
      }
      case PageConstants.RECURRING_TASKS_PAGE: {
         dispatch(setRecurringTasks(data.recurringTasks));
         break;
      }
      case PageConstants.ADMIN_PAGE: {
         dispatch(setUsers(data.users));
         break;
      }
      default: {
         console.log("Invalid page loaded");
      }
   }
}

export default pageData;