import React, {  useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { firebaseUI } from "../helpers/firebase"
import firebaseLoginConfig from "../config/firebase-login";
import FirebaseLogo from "../assets/firebase-logo.svg"

import Page from "./Page";

import 'firebaseui/dist/firebaseui.css'

const LoginPage = () => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.activeUser);

    // Firebase Auth UI Initializer
    // - Display Auth container once, if firebaseUI has been initialized
    useEffect(() => {
        if(firebaseUI && !user) {
            firebaseUI.start('#firebaseui-auth-container-login-page', firebaseLoginConfig);
        } else if(!firebaseUI) {
            //TODO proper error handling here
            alert("Firebase UI setup failed")
        }
    }, []);

    // Redirection Effect
    // - Redirect user to homepage if they are already logged in as a user
    useEffect(() => {
        if(user) {
            navigate("/")
        }
    }, [user]);

    return (
        <Page className="login-page" pageKey="login">
            <div className={"login-page__container"}>
                <h1 className="login-page__title">Login or Signup</h1>
                <p className="login-page__description">
                    Simply login with your chosen authentication provider to get started.
                </p><p className="login-page__description">
                    If you do not already have an account with us, one will be created for you.
                </p>
                <div id="firebaseui-auth-container-login-page" className="login-page__auth-container" />

                <p className="login-page__sub-text">
                    Our authentication is securely handled by Google Firebase. We do not handle any of your login information directly.
                </p>

                <img className="login-page__firebase-logo" src={FirebaseLogo} alt="Firebase Logo" />
            </div>
        </Page>
    )
};

export default LoginPage;
