import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext } from 'react-beautiful-dnd';

import Page from "./Page";
import TaskListContainer from "../components/tasks/TaskListContainer";
import { onDragEnd } from "../helpers/dnd";
import socket from "../socket";
import { PageConstants } from "../constants";
import useWindowDimensions from "../helpers/window";
import {setTaskTargeted} from "../redux/actions/tasks";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import {Box} from "@mui/material";

const TasksPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authenticated = useSelector(state => state.user.authenticated);
    const user = useSelector(state => state.user.activeUser);
    const [taskLists, setTaskLists] = useState({});
    const tasks = useSelector(state => state.tasks.taskSet);

    const [isMobile, setIsMobile] = useState(false);
    const { width, height } = useWindowDimensions();
    const [contentsHeight, setContentsHeight] = useState(height);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const headerTextSize = height < 768 ? 18 : height < 1920 ? 20 : 22;
        const headerlessHeight = height - ((headerTextSize + 2) + (2 * 9) + 1);
        setContentsHeight(headerlessHeight)
    }, [ width, height ])

    useEffect(() => {
        if(authenticated) {
            socket.emit("pageLoad", {page: PageConstants.TASK_PAGE});
        }
    }, [authenticated]);

    useEffect(() => {
        if(!authenticated && user === undefined) {
            //We check specifically for undefined as user begins as null, and is set to undefined on auth failure
            navigate("/")
        }
    }, [user, authenticated]);

    //Effect to split tasks into appropriate lists
    useEffect(() => {
        let newTaskLists;
        if(!isMobile) {
            newTaskLists = {
                targets: {
                    listId: "targets",
                    name: "Targets",
                    tasks: Object.values(tasks).filter(task => task.targeted)
                },
                backlog: {
                    listId: "backlog",
                    name: "Tasks",
                    tasks: Object.values(tasks).filter(task => !task.targeted)
                }
            };
        } else {
            newTaskLists = {
                all: {
                    listId: "all",
                    name: "Tasks",
                    tasks: Object.values(tasks)
                }
            }
        }

        setTaskLists(newTaskLists);
    }, [tasks, isMobile]);

    //Effect to determine view based on screen size
    useEffect(() => {
        if(width <= 768 ) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width]);

    const setListsCallback = (lists, movementDescription) => {
        const newTaskLists = {};
        lists.forEach((list) => {
            const listId = list.listId;
            newTaskLists[listId] = list;
        });

        const { taskId, from, to } = movementDescription;
        const { listId: fromListId } = from;
        const {listId: toListId, to: toIndex} = to;

        if(toListId === "targets" && fromListId !== "targets") {
            dispatch(setTaskTargeted({taskId, targeted: true}));
            socket.emit("setTaskTargeted", {taskId, targeted: true});
        } else if(toListId !== "targets" && fromListId === "targets") {
            dispatch(setTaskTargeted({taskId, targeted: false}));
            socket.emit("setTaskTargeted", {taskId, targeted: false});
        }

        setTaskLists(newTaskLists);
    };

    const targetsList = taskLists.targets;
    const backlogList = taskLists.backlog;

    return (
        <Page className="tasks-page" pageKey="tasks">
            <Box className="tasks-page__dnd-container" sx={{ height: contentsHeight }}>
                <DragDropContext onDragEnd={onDragEnd(Object.values(taskLists), setListsCallback)}>
                    {isMobile ?
                        <div className="tasks-page__primary-list-container scroller">
                            <TaskListContainer {...taskLists.all} searchTerm={searchTerm} />
                        </div>
                        :
                        <>
                            <div className="tasks-page__primary-list-container scroller">
                                {targetsList && <TaskListContainer {...targetsList} searchTerm={searchTerm} />}
                            </div>
                            <div className="tasks-page__secondary-lists-container scroller">
                                {backlogList && <TaskListContainer {...backlogList} searchTerm={searchTerm} />}
                            </div>
                        </>
                    }
                </DragDropContext>
            </Box>
            <Footer searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
        </Page>
    )
};

export default TasksPage;
