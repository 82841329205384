import {CircularProgress} from "@mui/material";
import {useEffect, useRef} from "react";

const NativeApp = () => {
    // This component contains a link to https://mindstack.app
    // A click on this link is simulated on page load to deep link into the app
    const ref = useRef();

    useEffect(() => {
        ref.current.click();
    }, []);

    return (
        <div>
            <CircularProgress />
            <a ref={ref} href="https://mindstack.app" style={{display: "none"}} id="native-app-link">Open Mindstack App</a>
        </div>
    );
}

export default NativeApp;