import { bubble as Menu } from 'react-burger-menu'
import {Link, useNavigate} from "react-router-dom";
import Button from "../input/button";
import {useSelector} from "react-redux";
import firebase from "firebase";
import React from "react";

const BurgerMenu = () =>  {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.activeUser);

    const onLogoutButtonClicked = () => {
        firebase.auth().signOut();
        navigate("/")
    }

    const onLoginButtonClicked = () => {
        navigate("/login")
    }

    return (
        <Menu>
            <Link to={"/"}>
                <h2>Home</h2>
            </Link>
            <Link to={"/tasks"}>
                <h2>Tasks</h2>
            </Link>
            <Link to={"/about"}>
                <h2>About</h2>
            </Link>
            {user ?
                <Button text="Logout" color="red" size="huge" onClick={onLogoutButtonClicked} />
                :
                <Button text="Login or Signup" color="orange" size="huge" onClick={onLoginButtonClicked} />
            }
        </Menu>
    )
};

export default BurgerMenu
