import React, {useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { setOverlayVisible } from "../../redux/actions/overlay"
import { useNavigate } from "react-router-dom";
import { Fab, TextField, Tooltip } from "@mui/material";
import {ReactComponent as AddIcon} from "../../assets/icons/add_black_24dp.svg";
import MoodIcon from '@mui/icons-material/Mood';
import EventRepeatIcon from "@mui/icons-material/EventRepeatOutlined";
import SearchIcon from "@mui/icons-material/SearchRounded";

const Footer = ({ searchTerm, setSearchTerm }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSearch, setShowSearch] = useState(false);
    const searchFieldRef = useRef(null);

    const onAddButtonClick = () => {
        navigate("/create-task");
    };

    const onGetTaskClick = () => {
        dispatch(setOverlayVisible({
            visible: true,
            overlayType: "getTask"
        }));
    }

    const onRecurringTasksClick = () => {
        navigate("/recurring-tasks");
    }

    const onSearchClick = () => {
        setShowSearch(!showSearch);
        if(showSearch) {
            setSearchTerm("");
        }
        else {
            searchFieldRef.current.focus();
        }
    }

    return (
        <div className="footer">
            <div className="footer_search-container">
                <TextField
                    className={`footer_search-field${showSearch ? " footer_search-field--show" : ""}`}
                    value={searchTerm}
                    placeholder="Search tasks"
                    inputRef={searchFieldRef}
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                />
                <Fab color="orange" size="medium"
                     className="footer__icon-button animate__animated animate__heartBeat animate__delay-2s"
                     onClick={onSearchClick}>
                    <SearchIcon fill="white" stroke="white" color="white"/>
                </Fab>
            </div>
            <Tooltip title="Recurring Tasks" placement="top">
                <Fab color="blue" size="medium"
                     className="footer__icon-button animate__animated animate__heartBeat animate__delay-2s"
                     onClick={onRecurringTasksClick}>
                    <EventRepeatIcon color="white"/>
                </Fab>
            </Tooltip>
            <Tooltip title="Mood" placement="top">
                <Fab color="purple" size="medium"
                     className="footer__icon-button animate__animated animate__heartBeat animate__delay-2s"
                     onClick={onGetTaskClick}>
                    <MoodIcon color="white"/>
                </Fab>
            </Tooltip>
            <Tooltip title="Create Task" placement="top">
                <Fab color="green" size="medium"
                     className="footer__icon-button animate__animated animate__heartBeat animate__delay-2s"
                     onClick={onAddButtonClick}>
                    <AddIcon fill="white" width={32} height={32}/>
                </Fab>
            </Tooltip>
        </div>
    )
};

export default Footer