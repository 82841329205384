import React from "react";
import { ThemeProvider } from "@mui/material";
import { useSelector } from 'react-redux'
import { RouterProvider } from "react-router-dom";

import theme from "./theme";
import { router } from "./navigation/router";
import Banner from "./components/banner";
import AppUrlListener from "./AppUrlListener";

function App() {
    const bannersState = useSelector(state => state.banners);
    const bannerArray = Object.entries(bannersState)
        .filter((banner) => {
            return banner[1].text //Make sure we actually have a text field
        })
        .map((bannerEntry) => {
            const key = bannerEntry[0];
            const banner = bannerEntry[1];
            return <Banner bannerKey={key} bannerText={banner.text} />
        })

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}>
                <AppUrlListener />
                {bannerArray}
            </RouterProvider>
        </ThemeProvider>
  );
}

export default App;
