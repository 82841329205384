import React  from 'react';
import Page from "./Page";
import Splash from "../components/home/Splash";
import TaskPrioritization from "../components/home/TaskPrioritization";
import LabelsAndNotes from "../components/home/LabelsAndNotes";
import Deadlines from "../components/home/Deadlines";

const HomePage = () => {
    return (
        <Page className="home-page" pageKey="home">
            <Splash />
            <TaskPrioritization />
            <LabelsAndNotes />
            <Deadlines />
        </Page>
    )
};

export default HomePage;