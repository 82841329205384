import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import * as firebaseui from 'firebaseui'
import socket from "../socket";
import { dispatch } from "../redux/store";
import {setDefinitions} from "../redux/actions/definitions";
import {setUser} from "../redux/actions/user";

let firebaseUI = null;
let database = null;

export const initialiseFirebase = () => {
    let firebaseConfig = {
        apiKey: "AIzaSyAziLooNNQRWDNaUpuiKKzZExOWzun65d4",
        authDomain: "mindstack-368413.firebaseapp.com",
        databaseURL: "https://mindstack-368413-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "mindstack-368413",
        storageBucket: "mindstack-368413.appspot.com",
        messagingSenderId: "717481736708",
        appId: "1:717481736708:web:c7aac2416826a18a42d351",
        measurementId: "G-BX2BH0EMQZ"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    database = firebase.database();
    getDefinitions();

    firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());
};

export const authenticateUser = () => {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            // User is signed in.
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                console.log("Authenticating");
                socket.emit("authenticateSocket", {
                    idToken
                })

                const {uid, email, displayName} = user;
                dispatch(setUser({
                    uid,
                    email,
                    displayName
                }));
            }).catch(function(error) {
                // Handle error
            });
        } else {
            // No user is signed in.
            dispatch(setUser(undefined));
            console.log("logged out")
        }
    });
}

export const getDefinitions = () => {
    database.ref("definitions").on("value", (snapshot) => {
        const definitions = snapshot.val();
        dispatch(setDefinitions(definitions));
    })
}

export {firebaseUI, database};
